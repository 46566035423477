
.row-dragging {
  background: #fafafa;
  border: 1px solid #ccc;
}
.row-dragging .drag-visible-hidden {
  padding: 16px;
  width: 100%;
  visibility: hidden;
}
.row-dragging .drag-visible {
  visibility: visible;
  min-width: 145px;
  padding-top: 16px;
  padding-bottom: 16px;
}
.json-form-array-table-row-item .ant-form-item-explain{
  display: none;
}


th.ant-column-item-required::after {
  display: inline-block;
  margin-left: 4px;
  color: #ff4d4f;
  font-size: 14px;
  font-family: SimSun, sans-serif;
  line-height: 1;
  content: '*';
}

.table-act-source {
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-bottom: 2px !important;
  padding-top: 2px !important; 
  width: 182px;
  font-size: 14px;
  line-height: 1.2;
}

.table-act-source th {
  font-weight: unset;
}


