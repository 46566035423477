.title {
    font-weight: bold;
    word-break: keep-all;
}

.text {
    color: #a8adb4;
}

.TimelineItemHeaderText {
    font: inherit;
    align-items: baseline;
}
