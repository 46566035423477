.uploadWrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
}

.appealTimeline {
    margin-top: 3rem;
    margin-left: auto;
    flex: 0 0 45%;
}

.appealFilesTimeline {
    margin-top: 3rem;
    margin-right: auto;
    flex: 0 0 45%;
}
