.uploadWrapper {
    display: flex;
    flex-direction: column-reverse;
    gap: 12px;
}

.hrTimeline {
    margin-top: 3rem;
    margin-left: auto;
    flex: 0 0 45%
}

.hrFilesTimeline {
    margin-top: 3rem;
    margin-right: auto;
    flex: 0 0 45%
}
